var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('MainBanner',{attrs:{"Title":this.pageContent.page && this.pageContent.page[1].title,"Image":"/img/main-banner.webp","TextPosition":"bottom","Height":"full"}}),_c('div',{staticClass:"main-continer mt-5"},[_c('div',{staticClass:"container row mx-auto mb-5"},[(this.pageContent)?_c('div',{staticClass:"col-md-6 contact-map"},[_c('div',{domProps:{"innerHTML":_vm._s(this.pageContent.page[0].content)}}),_vm._m(0)]):_vm._e(),_c('div',{staticClass:"col-md-6 mb-5 mt-5 mt-md-0"},[_c('form',{staticClass:"w-100 contact-form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"userName"}},[_vm._v("Adınız Soyadınız")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.userName),expression:"form.userName"}],staticClass:"form-control",class:{
                error: _vm.$v.form.userName.$error,
                valid: _vm.$v.form.userName.$dirty && !_vm.$v.form.userName.$invalid,
              },attrs:{"type":"text"},domProps:{"value":(_vm.form.userName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "userName", $event.target.value)},_vm.removeSpace]}}),(!_vm.$v.form.userName.alpha)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" Ad Soyad şeklinde giriniz. ")]):_vm._e(),(!_vm.$v.form.userName.nameSurnameLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" En az 2 karakter girmelisiniz ")]):_vm._e(),(!_vm.$v.form.userName.minLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" En az 2 karakter girmelisiniz ")]):_vm._e(),(!_vm.$v.form.userName.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v(" Bu alan zorunludur. ")]):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{attrs:{"for":"userMail"}},[_vm._v("E-Posta")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.userMail),expression:"form.userMail"}],staticClass:"form-control",class:{
                  error: _vm.$v.form.userMail.$error,
                  valid:
                    _vm.$v.form.userMail.$dirty && !_vm.$v.form.userMail.$invalid,
                },attrs:{"inputmode":"email","type":"email"},domProps:{"value":(_vm.form.userMail)},on:{"keydown":_vm.key,"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "userMail", $event.target.value)}}}),(!_vm.$v.form.userMail.email)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Yanlış mail formatı ")]):_vm._e(),(!_vm.$v.form.userMail.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Bu alan zorunludur. ")]):_vm._e()]),_c('div',{staticClass:"form-group col-12 col-md-6"},[_c('label',{attrs:{"for":"userPhone"}},[_vm._v("Telefon Numarası")]),_c('the-mask',{staticClass:"form-control",class:{
                  error: _vm.$v.form.userPhone.$error,
                  valid:
                    _vm.$v.form.userPhone.$dirty && !_vm.$v.form.userPhone.$invalid,
                },attrs:{"name":"phone","id":"frmPhoneNumA","autocomplete":"tel","mask":"0(###) ### ## ##","placeholder":"0(---) --- -- --","type":"tel"},model:{value:(_vm.form.userPhone),callback:function ($$v) {_vm.$set(_vm.form, "userPhone", $$v)},expression:"form.userPhone"}}),(!_vm.$v.form.userPhone.required)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Bu alan zorunludur. ")]):_vm._e(),(!_vm.$v.form.userPhone.minLength)?_c('p',{staticClass:"form-warning"},[_c('span',{staticClass:"svg-icon icons-alert"}),_vm._v("Yanlış telefon formatı ")]):_vm._e()],1)]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"userAddress"}},[_vm._v("Mesajınız")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.userMessage),expression:"form.userMessage"}],staticClass:"form-control",class:{
                error: _vm.$v.form.userAddress.$error,
                valid:
                  _vm.$v.form.userAddress.$dirty && !_vm.$v.form.userAddress.$invalid,
              },attrs:{"type":"text"},domProps:{"value":(_vm.form.userMessage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "userMessage", $event.target.value)}}}),(!_vm.$v.form.userAddress.required)?_c('p',{staticClass:"form-warning"},[_vm._v(" Bu alan zorunludur. ")]):_vm._e(),(!_vm.$v.form.userAddress.minLength)?_c('p',{staticClass:"form-warning"},[_vm._v(" En az 10 karakter giriniz. ")]):_vm._e()]),_c('label',{staticClass:"custom-checkbox"},[_c('a',{attrs:{"href":"https://www.bilimseferberligi.org/kvkk","target":"_blank"}},[_vm._v("Kişisel verilerin korunması ve işlenmesine ilişkin politikayı ")]),_vm._v(" okudum, onaylıyorum. "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.terms_accepted),expression:"form.terms_accepted"}],class:{
                error: _vm.$v.form.terms_accepted.$error,
                valid:
                  _vm.$v.form.terms_accepted.$dirty &&
                  !_vm.$v.form.terms_accepted.$invalid,
              },attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.terms_accepted)?_vm._i(_vm.form.terms_accepted,null)>-1:(_vm.form.terms_accepted)},on:{"change":function($event){var $$a=_vm.form.terms_accepted,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "terms_accepted", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "terms_accepted", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "terms_accepted", $$c)}}}}),_c('span',{staticClass:"checkmark"})]),_c('div',{staticClass:"captchaContainer"},[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":"6LdqRYkdAAAAADMTRrN8tCiANfX80guwDWlQ7B7A"},on:{"verify":_vm.markRecaptchaAsVerified}})],1),_c('div',{staticClass:"mt-4"},[_c('ButtonRounded',{attrs:{"Text":"Gönder","isRouting":false,"color":"blue","disabled":_vm.disabled}})],1)]),(_vm.returnMsg.length > 0)?_c('p',{staticClass:"mb-5 mt-3",domProps:{"innerHTML":_vm._s(_vm.returnMsg)}}):_vm._e()])])]),_c('FooterLanding')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4 d-flex justify-content-start"},[_c('a',{staticClass:"\n              button-rounded\n              blue\n              d-flex\n              align-items-center\n              justify-content-center\n              float-right\n              mt-4\n            ",attrs:{"href":"https://www.google.com/maps/place/Özyeğin+Üniversitesi/@41.0304414,29.256979,17z/data=!4m9!1m2!2m1!1zTmnFn2FudGVwZSBNYWguIE9ybWFuIFNvay4gTm86MTMsIMOWenllxJ9pbiDDnG5pdmVyc2l0ZXNpIMOWxJ9yZW5jaSBNZXJrZXppLCBObzozNC0zNiwgMzQ3OTQgQWxlbWRhxJ8gLSDDh2VrbWVrw7Z5!3m5!1s0x14cad220ec73b7a7:0x64de71fee7408a9!8m2!3d41.0293877!4d29.2592447!15sCnJOacWfYW50ZXBlIE1haC4gT3JtYW4gU29rLiBObzoxMywgw5Z6eWXEn2luIMOcbml2ZXJzaXRlc2kgw5bEn3JlbmNpIE1lcmtlemksIE5vOjM0LTM2LCAzNDc5NCBBbGVtZGHEnyAtIMOHZWttZWvDtnmSAQp1bml2ZXJzaXR5","target":"_blank"}},[_vm._v("Haritada Göster")])])}]

export { render, staticRenderFns }