<template>
  <div>
    <MainBanner
      :Title="this.pageContent.page && this.pageContent.page[1].title"
     
      Image="/img/main-banner.webp"
      TextPosition="bottom"
      Height="full"
    />
    <div class="main-continer mt-5">
      <div class="container row mx-auto mb-5">
        <div class="col-md-6 contact-map" v-if="this.pageContent">
          <div v-html="this.pageContent.page[0].content"></div>
          <div class="mt-4 d-flex justify-content-start">
            <a
              class="
                button-rounded
                blue
                d-flex
                align-items-center
                justify-content-center
                float-right
                mt-4
              "
              href="https://www.google.com/maps/place/Özyeğin+Üniversitesi/@41.0304414,29.256979,17z/data=!4m9!1m2!2m1!1zTmnFn2FudGVwZSBNYWguIE9ybWFuIFNvay4gTm86MTMsIMOWenllxJ9pbiDDnG5pdmVyc2l0ZXNpIMOWxJ9yZW5jaSBNZXJrZXppLCBObzozNC0zNiwgMzQ3OTQgQWxlbWRhxJ8gLSDDh2VrbWVrw7Z5!3m5!1s0x14cad220ec73b7a7:0x64de71fee7408a9!8m2!3d41.0293877!4d29.2592447!15sCnJOacWfYW50ZXBlIE1haC4gT3JtYW4gU29rLiBObzoxMywgw5Z6eWXEn2luIMOcbml2ZXJzaXRlc2kgw5bEn3JlbmNpIE1lcmtlemksIE5vOjM0LTM2LCAzNDc5NCBBbGVtZGHEnyAtIMOHZWttZWvDtnmSAQp1bml2ZXJzaXR5"
              target="_blank"
              >Haritada Göster</a
            >
          </div>
        </div>
        <div class="col-md-6 mb-5 mt-5 mt-md-0">
          <form
            @submit.prevent="submitForm"
            autocomplete="off"
            class="w-100 contact-form"
          >
            <div class="form-group">
              <label for="userName">Adınız Soyadınız</label>
              <input
                v-model="form.userName"
                v-on:input="removeSpace"
                v-bind:class="{
                  error: $v.form.userName.$error,
                  valid: $v.form.userName.$dirty && !$v.form.userName.$invalid,
                }"
                type="text"
                class="form-control"
              />
              <p class="form-warning" v-if="!$v.form.userName.alpha">
                <span class="svg-icon icons-alert"></span> Ad Soyad şeklinde
                giriniz.
              </p>
              <p
                class="form-warning"
                v-if="!$v.form.userName.nameSurnameLength"
              >
                <span class="svg-icon icons-alert"></span> En az 2 karakter
                girmelisiniz
              </p>
              <p class="form-warning" v-if="!$v.form.userName.minLength">
                <span class="svg-icon icons-alert"></span> En az 2 karakter
                girmelisiniz
              </p>
              <p class="form-warning" v-if="!$v.form.userName.required">
                <span class="svg-icon icons-alert"></span> Bu alan zorunludur.
              </p>
            </div>

            <div class="row">
              <div class="form-group col-12 col-md-6">
                <label for="userMail">E-Posta</label>
                <input
                  v-model="form.userMail"
                  v-on:keydown="key"
                  v-bind:class="{
                    error: $v.form.userMail.$error,
                    valid:
                      $v.form.userMail.$dirty && !$v.form.userMail.$invalid,
                  }"
                  inputmode="email"
                  type="email"
                  class="form-control"
                />
                <p class="form-warning" v-if="!$v.form.userMail.email">
                  <span class="svg-icon icons-alert"></span>Yanlış mail formatı
                </p>
                <p class="form-warning" v-if="!$v.form.userMail.required">
                  <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
                </p>
              </div>

              <div class="form-group col-12 col-md-6">
                <label for="userPhone">Telefon Numarası</label>
                <the-mask
                  name="phone"
                  id="frmPhoneNumA"
                  autocomplete="tel"
                  mask="0(###) ### ## ##"
                  placeholder="0(---) --- -- --"
                  v-model="form.userPhone"
                  v-bind:class="{
                    error: $v.form.userPhone.$error,
                    valid:
                      $v.form.userPhone.$dirty && !$v.form.userPhone.$invalid,
                  }"
                  type="tel"
                  class="form-control"
                />
                <p class="form-warning" v-if="!$v.form.userPhone.required">
                  <span class="svg-icon icons-alert"></span>Bu alan zorunludur.
                </p>
                <p class="form-warning" v-if="!$v.form.userPhone.minLength">
                  <span class="svg-icon icons-alert"></span>Yanlış telefon
                  formatı
                </p>
              </div>
            </div>

            <div class="form-group">
              <label for="userAddress">Mesajınız</label>
              <textarea
                v-model="form.userMessage"
                v-bind:class="{
                  error: $v.form.userAddress.$error,
                  valid:
                    $v.form.userAddress.$dirty && !$v.form.userAddress.$invalid,
                }"
                type="text"
                class="form-control"
              ></textarea>
              <p class="form-warning" v-if="!$v.form.userAddress.required">
                Bu alan zorunludur.
              </p>
              <p class="form-warning" v-if="!$v.form.userAddress.minLength">
                En az 10 karakter giriniz.
              </p>
            </div>

            <label class="custom-checkbox">
              <a
                href="https://www.bilimseferberligi.org/kvkk"
                target="_blank"
                >Kişisel verilerin korunması ve işlenmesine ilişkin politikayı
              </a>
              okudum, onaylıyorum.
              <input
                type="checkbox"
                v-model="form.terms_accepted"
                v-bind:class="{
                  error: $v.form.terms_accepted.$error,
                  valid:
                    $v.form.terms_accepted.$dirty &&
                    !$v.form.terms_accepted.$invalid,
                }"
              />
              <span class="checkmark"></span>
            </label>

            <div class="captchaContainer">
              <vue-recaptcha 
                sitekey="6LdqRYkdAAAAADMTRrN8tCiANfX80guwDWlQ7B7A"
                @verify="markRecaptchaAsVerified"
                ref="recaptcha"
                
              ></vue-recaptcha>
            </div>

            <div class="mt-4">
              <ButtonRounded
                Text="Gönder"
                :isRouting="false"
                color="blue"
                :disabled="disabled"
              />
            </div>
          </form>
          <p
            v-if="returnMsg.length > 0"
            v-html="returnMsg"
            class="mb-5 mt-3"
          ></p>
        </div>
      </div>
    </div>
    <FooterLanding />
  </div>
</template>
<script>
import ButtonRounded from "@/components/ButtonRounded";
import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";

import { VueRecaptcha } from 'vue-recaptcha';

import {
  required,
  email,
  minLength,
  helpers,
  requiredIf,
} from "vuelidate/lib/validators";
const nameSurnameLength = (value) => {
  let isValid = true;
  value.split(" ").forEach((e) => {
    if (e.length < 2) {
      isValid = false;
    }
  });
  return isValid;
};
export default {
  name: "Contact",
  components: { MainBanner, ButtonRounded, FooterLanding, VueRecaptcha },
  data() {
    return {
      recaptchaVerified: false,
      disabled: false,
      isLoading: false,
      fullPage: false,
      pageContent: '',
      form: {
        userName: "",
        userMail: "",
        userPhone: "",
        userMessage: "",
        terms_accepted: false,
      },
      returnMsg: "",
    };
  },
  validations: {
    form: {
      userName: {
        required,
        nameSurnameLength,
        minLength: minLength(2),
        alpha: helpers.regex(
          "alpha",
          /^[a-z\u00C0-\u02AB'´`]+\.?\s([a-z\u00C0-\u02AB'´`]+\.?\s?)+$/i
        ),
      },
      userMail: {
        required,
        email: email,
      },
      userPhone: {
        required,
        minLength: minLength(10),
      },

      userAddress: {
        // required,
        // minLength: minLength(11),
      },
      terms_accepted: {
        checked: (value) => value === true,
      },
    },
  },
    metaInfo() {
    return {
      title: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.',
      meta: [
        {
          vmid: "description",
          name: "description",
          //content: this.metaData.description,
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { property: "og:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { name: "twitter:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { name: "twitter:url", content: window.location.href},
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  mounted(){
    this.getPageContent("contact");
  },
  methods: {
    markRecaptchaAsVerified(response){
      this.recaptchaVerified = true;
    },
    getPageContent(page) {
      this.$api.getPageContent(page).then((response) => {
        this.pageContent = response;
      });
    },
    removeSpace: function (event) {
      this.form.userName = this.form.userName
        .replace(/\s\s+/g, " ")
        .trimStart();
    },
    key: function (event) {
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    },
    submitForm() {
      let captchaContainer = document.querySelector(".captchaContainer");
      
      this.$v.$touch();
      
      if (!this.$v.form.$invalid) {
        if(this.recaptchaVerified){
        captchaContainer.classList.remove("error");
        
        let data = new FormData();
        data.append("name_surname", this.form.userName);
        data.append("email", this.form.userMail);
        data.append("phone", this.form.userPhone);
        data.append("message", this.form.userMessage);
        this.disabled = true;
        this.$api.sendContactForm(data).then((response) => {
          this.returnMsg = response.message;
          setTimeout(() => {
            this.disabled = false;
          }, 1500);
        });

      }else{
        captchaContainer.classList.add("error");
      }

      } else {}

    },
  },
};
</script>
<style lang="scss">
.contact-map {
  h2 {
    font-size: 1.75rem;
    font-weight: 500;
  }
  h3 {
    font-size: 1.125rem;
    font-weight: 600;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    b {
      font-weight: 600;
    }
  }
  
}
.contact-form {
  textarea {
    height: 180px;
  }
}
.contact-form .custom-selectbox,
.contact-form input.form-control,
.contact-form textarea.form-control {
  background: var(--blueLight);
  border: 2px solid var(--blueLight);
  color: #fff;
}
</style>